import { graphql } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';

import { Content } from '../../common/content';
import './headline.scss';

const bem = new BEMHelper({
  name: 'content-headline',
});

export const Headline = ({
  id,
  headline,
}) => (
  <Content id={id} {...bem()}>
    <h2 {...bem('headline')}>
      {parse(headline)}
    </h2>
  </Content>
);

Headline.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
};

export const fragment = graphql`
  fragment HeadlineContentFragment on CraftGQL_pageContents_headline_BlockType {
    id
    headline
  }
`;
